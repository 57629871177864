import styled from 'styled-components';
import StyledIcon from '../../../atoms/Icon/styles';
import colors from '../../../theme/constants/colors';
import {StyledListItemProps, StyledProps} from './types';
import zIndex from '../../../theme/constants/zIndex';

const size = 22;
const cellHeight = 50;

const positioning = (position) => {
  switch (position) {
    case 'top-right':
      return `top: 0;
              right: 0;`;
    case 'top-left':
      return `top: 0;
              left: 0;`;
    case 'bottom-right':
      return `bottom: 0;
              right: 0;`;
    case 'bottom-left':
      return `bottom: 0;
              left: 0;`;
    default:
      return `top: 0;
              right: 0;`;
  }
};

export const StyledStaffToolsCog = styled.button`
  width: ${size}px;
  height: ${size}px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  margin: 10px;
  transition: all 0.1s ease-in;

  ${StyledIcon} {
    transition: color 0.1s ease-in, transform 0.4s ease-in;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.7);

    ${StyledIcon} {
      color: ${colors.red100};
      color: ${colors.green100};
      transform: rotate(90deg);
    }
  }
`;

export const StyledDropdown = styled.ul`
  position: absolute;
  list-style: none;
  padding: 5px 0;
  width: fit-content;
  background: ${colors.white};
  z-index: ${zIndex.dropdown};
  box-shadow: 0 2px 10px 4px rgba(0, 0, 0, 0.11);
  left: 50%;
  transform: translateX(-50%);
  border-radius: 15px;

  &::before {
    content: '';
    position: absolute;
    width: 0;
    top: -9px;
    right: 0;
    left: 0;
    margin: 0 auto;
    border-bottom: 10px solid ${colors.white};
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
  }
`;

export const StyledListItem = styled.li<StyledListItemProps>`
  height: ${cellHeight}px;
  width: 100%;
  white-space: nowrap;
  line-height: ${cellHeight}px;
  text-align: center;
  transition: background 0.3s;
  cursor: pointer;

  > div {
    margin: 0 15px;
    padding: 0 15px;
  }

  ${StyledIcon} {
    margin-right: 10px;
  }

  &:hover {
    background: rgba(124, 224, 211, 0.2);
  }

  &:not(:last-child) {
    > div {
      border-bottom: 1px solid ${colors.grey50};
    }
  }

  &:first-child {
    border-radius: 6px 6px 0 0;
  }

  &:last-child {
    border-radius: 0 0 6px 6px;
  }

  ${(props) => {
    switch (props.$status) {
      case 'loading':
        return `
        cursor: default;
        background: rgba(124, 224, 211, 0.2);`;
      case 'success':
        return `
        cursor: default;
        background: rgba(75, 237, 66, 0.4);
        &:hover {
          background: rgba(75, 237, 66, 0.4);
        }`;
      case 'failed':
        return `
        background: rgba(255, 23, 73, 0.2);
        &:hover {
          background: rgba(255, 23, 73, 0.2);
        }`;
      case 'disabled':
        return `
        cursor: default;
        color: ${colors.grey100};
        &:hover {
          background: transparent;
        }
        `;
      default:
        return;
    }
  }}
`;

export const StyledStaffToolsContainer = styled.div`
  position: relative;
`;

export const StyledPositionContainer = styled.div<StyledProps>`
  position: absolute;
  ${positioning((props) => props.$position)}
`;
