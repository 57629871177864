import React, {ReactElement} from 'react';
import SVG from '../../../components/icons-af/check';
import StyledCheckboxLabel from './styles';
import Props from './typings';

const Checkbox = ({
  checked,
  value,
  label,
  group,
  style = 'text',
  disabled = false,
  callback,
}: Props): ReactElement => {
  return (
    <StyledCheckboxLabel
      aria-disabled={disabled}
      $checked={checked}
      $disabled={disabled}
      $style={style}
      $title={label}
    >
      <input type="checkbox" name={group} checked={checked} onChange={() => callback(value)} />
      {label}
      <SVG width="20px" />
    </StyledCheckboxLabel>
  );
};

export default Checkbox;
