import constantsFactory from '@/utils/constants';
import {getDimensions} from '@/utils/helpers/string';
import dynamic from 'next/dynamic';
import {ReactElement, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {saveProductFavourite} from '../../../../../redux/actions/productFavouriteActions';
import {productClick} from '../../../../../redux/actions/trackingActions';
import {selectProductFavouritesData} from '../../../../../redux/reducers/productFavouritesReducer';
import useAuth from '../../../../hooks/use-auth/use-auth';
import {
  FALLBACK_WIDTH,
  PRODUCT_CARD_SIZES,
  productCardImageSrcSetDef,
} from '../../../../services/image-loader';
import {emit} from '../../../../utils/events';
import {pushGAAddToWishlistEvent, pushGASelectItemEvent} from '../../../../utils/gtm';
import Image from '../../../atoms/Image/Image';
import Price from '../../../atoms/Price/Price';
import FavouriteButton from '../../Buttons/Favourite/FavouriteButton';
import StaffToolsCog from '../../Staff/ToolsCog/StaffToolsCog';
import StyledProductCard from './styles';
import Props from './typings';

const {DATA_TEST_ID} = constantsFactory();

const PromotionStarWithNoSSR = dynamic(() => import('../../PromotionStar/PromotionStar'), {
  ssr: false,
});

const ProductCard = ({
  product,
  displaySizeUnits,
  displayCurrency,
  currencySymbol,
  lazyLoading = true,
  respectAspectRatio = true,
}: Props): ReactElement => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const favourites = useSelector(selectProductFavouritesData);
  const isFavouriteProductFn = () =>
    favourites ? favourites.find((element) => element.id === product.id) : false;
  const [dimensionsLabel, setDimensionsLabel] = useState(
    getDimensions(product.dimensions, displaySizeUnits) ?? '',
  );

  const getImagePath = () => {
    const url = product?.images?.[0]?.path;
    return url;
  };

  const handleProductClick = (e, product) => {
    pushGASelectItemEvent(product, displayCurrency);
    trackProductClick();
  };

  const trackProductClick = () => {
    const url = `/product/${product.slug}/`;
    dispatch(productClick(url, product.slug));
  };

  const favouriteOnClick = () => {
    if (!auth.isAuthenticated()) {
      emit('CLICK_ON_JOIN');
      return;
    }

    const onSuccessCallbacks: {(): void}[] = [];
    if (!isFavouriteProductFn()) {
      onSuccessCallbacks.push(() => pushGAAddToWishlistEvent(product, displayCurrency));
    }

    dispatch(
      saveProductFavourite(
        product.id,
        product.slug,
        product.artist_id,
        product.artist_slug,
        onSuccessCallbacks,
      ),
    );
  };

  useEffect(() => {
    setDimensionsLabel(getDimensions(product.dimensions, displaySizeUnits));
  }, [displaySizeUnits]);

  return (
    <StyledProductCard
      $hasPrints={!!product.lowest_print_prices}
      $respectAspectRatio={respectAspectRatio}
      data-testid={DATA_TEST_ID.PRODUCT_CARD}
    >
      <Image
        path={getImagePath()}
        alt={`${product?.name} by ${product?.artist_name}`}
        mainColor={product?.average_colour}
        width={product?.images?.[0]?.width}
        height={product?.images?.[0]?.height}
        srcSetDef={productCardImageSrcSetDef(product?.images?.[0]?.path)}
        sizes={PRODUCT_CARD_SIZES}
        responsive
        loading={lazyLoading === true ? 'lazy' : 'eager'}
        fallbackWidth={FALLBACK_WIDTH}
      />

      <section>
        {product.cb && <PromotionStarWithNoSSR type={product.cb} />}

        <h5>{product.artist_name}</h5>

        <h6>{product.category_name}</h6>

        {displaySizeUnits && <p>{dimensionsLabel}</p>}

        <Price
          price={product.prices[displayCurrency]}
          oldPrice={
            product.quantity > 0 && product.original_prices
              ? product.original_prices[displayCurrency]
              : undefined
          }
          currency={currencySymbol}
          sold={product.quantity === 0}
        />
      </section>

      {false && (
        <div className="print-container">
          <span className="printsTag">Prints available</span>
        </div>
      )}

      <a
        href={`/product/${product.slug}/`}
        onClick={(e) => handleProductClick(e, product)}
        aria-label={product?.name ?? product.slug}
      />

      <FavouriteButton active={isFavouriteProductFn()} onClick={favouriteOnClick} />

      <StaffToolsCog product={product} />
    </StyledProductCard>
  );
};

export default ProductCard;
