import styled, {css} from 'styled-components';
import colors from '../../../theme/constants/colors';
import Typography from '../../../theme/typography';
import {StyledProps} from './typings';
import StyledFavouriteBtn from '../../Buttons/Favourite/styles';
import StyledIcon from '../../../atoms/Icon/styles';

const StyledProductCard = styled.div<StyledProps>`
  position: relative;
  background-color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  width: 260px;
  cursor: pointer;
  ${(props) => props.$hasPrints && css``}

  & figure {
    border-radius: 4px 4px 0 0;
    ${(props) => !props.$respectAspectRatio && `aspect-ratio: 1 / 1;`}
  }

  > section {
    padding: 5px 10px;
    position: relative;

    > h5 {
      ${Typography.Subtitle1}
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    > h6 {
      ${Typography.Body1}
    }

    > p {
      ${Typography.Body2}
      margin-bottom: 7px;
    }

    ${StyledIcon} {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  .print-container {
    height: 0;
  }

  .printsTag {
    ${Typography.Body2}
    padding: 0 10px;
    background: ${colors.grey50};
    border-radius: 25px;
    display: inline-block;
    margin-left: 15px;
    margin-bottom: 15px;
    opacity: 0;
  }

  > a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  > ${StyledFavouriteBtn} {
    position: absolute;
    bottom: 5px;
    right: 10px;
  }

  &:hover {
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.14);
    /* z-index: 10;  removing as not in use now */

    .print-container {
      height: 39px;
    }

    .printsTag {
      opacity: 1;
      transition: opacity 0.4s ease-out;
    }
  }
`;

export default StyledProductCard;
