import styled from 'styled-components';
import colors from '../../../../../theme/constants/colors';
import typography from '../../../../../theme/typography';
import StyledCtaButton from '../../../../../atoms/Buttons/CTA/styles';
import breakpoints from '../../../../../theme/constants/breakpoints';
import {StyledSlidingMsgProps} from './typings';
import StyledCheckboxLabel from '../../../../../atoms/Checkbox/styles';

export const StyledCampaigns = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    ${typography.Title}
    color: ${colors.darkBlue100};
    text-align: center;
    margin: 5px 0;
    max-width: calc(100% - 15px);

    &::before {
      content: '';
      display: inline-block;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: ${colors.red100};
      margin-left: -18px;
      margin-right: 5px;

      @media only screen and ${breakpoints.device.l} {
        width: 15px;
        height: 15px;
        margin-left: -20px;
      }
    }
  }

  h5 {
    ${typography.Body1}
    color: ${colors.darkBlue50};
    text-align: center;
    margin: 2px 0 10px;
  }
`;

export const StyledEmbeddedMsg = styled.div`
  padding: 25px 0 15px;
  text-align: center;

  p {
    ${typography.Art}
  }

  ${StyledCtaButton} {
    margin: 15px auto 0;
  }
`;

export const StyledChecklistArea = styled.div`
  overflow-y: scroll;
  margin: 15px 0 0;
  align-self: flex-start;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    background: ${colors.white};
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${colors.red100};
  }
  &::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: ${colors.white};
  }

  > ${StyledCheckboxLabel} {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const StyledSlidingMsg = styled.p<StyledSlidingMsgProps>`
  margin-top: ${(props) => (props.$show === true ? '15px' : '0px')};
  background: ${colors.red25};
  border-radius: 5px;
  ${typography.Body2}
  color: ${colors.red200};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  overflow: hidden;
  height: ${(props) => (props.$show === true ? '60px' : '0px')};
  padding: 0 15px;
  box-shadow: inset 0 0 0 1px ${colors.red50};
  transition: height 0.2s;
`;
