// Action Types
import {
  STATUS_LOADING,
  GET_PRODUCT_THEMES_REQUEST,
  GET_PRODUCT_THEMES_SUCCESS,
  GET_PRODUCT_THEMES_FAILURE,
  ADD_PRODUCT_THEME_REQUEST,
  ADD_PRODUCT_THEME_SUCCESS,
  ADD_PRODUCT_THEME_FAILURE,
  DELETE_PRODUCT_THEME_REQUEST,
  DELETE_PRODUCT_THEME_SUCCESS,
  DELETE_PRODUCT_THEME_FAILURE,
  GET_PRODUCT_CAMPAIGNS_REQUEST,
  GET_PRODUCT_CAMPAIGNS_SUCCESS,
  GET_PRODUCT_CAMPAIGNS_FAILURE,
  ADD_PRODUCT_CAMPAIGN_REQUEST,
  ADD_PRODUCT_CAMPAIGN_SUCCESS,
  ADD_PRODUCT_CAMPAIGN_FAILURE,
  DELETE_PRODUCT_CAMPAIGN_REQUEST,
  DELETE_PRODUCT_CAMPAIGN_SUCCESS,
  DELETE_PRODUCT_CAMPAIGN_FAILURE,
  PROMOTE_PRODUCT_REQUEST,
  PROMOTE_PRODUCT_SUCCESS,
  PROMOTE_PRODUCT_FAILURE,
  GET_PRODUCT_COLLECTIONS_REQUEST,
  GET_PRODUCT_COLLECTIONS_SUCCESS,
  GET_PRODUCT_COLLECTIONS_FAILURE,
  ADD_PRODUCT_COLLECTION_REQUEST,
  ADD_PRODUCT_COLLECTION_SUCCESS,
  ADD_PRODUCT_COLLECTION_FAILURE,
  DELETE_PRODUCT_COLLECTION_REQUEST,
  DELETE_PRODUCT_COLLECTION_SUCCESS,
  DELETE_PRODUCT_COLLECTION_FAILURE,
  CREATE_NEW_COLLECTION_REQUEST,
  CREATE_NEW_COLLECTION_SUCCESS,
  CREATE_NEW_COLLECTION_FAILURE,
} from '../actionTypes';
import axios from 'axios';
import constantsFactory from '../../src/utils/constants';

const {API} = constantsFactory();

export const getThemes = (slug) => ({
  types: [GET_PRODUCT_THEMES_REQUEST, GET_PRODUCT_THEMES_SUCCESS, GET_PRODUCT_THEMES_FAILURE],
  shouldCallAPI: (state) => {
    return state.staffOnly.themes.status !== STATUS_LOADING;
  },
  callCache: (state) => {
    if (!state.staffOnly.themes.data[slug]) return false;

    return {
      data: state.staffOnly.themes.data[slug],
    };
  },
  callAPI: () =>
    axios.get(`${API.PRODUCT_THEMES}${slug}/`, {
      headers: {Accept: 'application/json'},
    }),
  payload: {slug},
});

export const addTheme = (slug, themeId, errorCallbacks) => ({
  types: [ADD_PRODUCT_THEME_REQUEST, ADD_PRODUCT_THEME_SUCCESS, ADD_PRODUCT_THEME_FAILURE],
  callAPI: () =>
    axios.post(
      `${API.PRODUCT_THEMES}${slug}/product-theme/`,
      {theme: themeId},
      {headers: {Accept: 'application/json'}},
    ),
  payload: {slug, themeId},
  onError: errorCallbacks,
});

export const deleteTheme = (slug, themeId, errorCallbacks) => ({
  types: [DELETE_PRODUCT_THEME_REQUEST, DELETE_PRODUCT_THEME_SUCCESS, DELETE_PRODUCT_THEME_FAILURE],
  callAPI: () =>
    axios.delete(`${API.PRODUCT_THEMES}${slug}/product-theme/${themeId}/`, {
      headers: {Accept: 'application/json'},
    }),
  payload: {slug, themeId},
  onError: errorCallbacks,
});

export const getCampaigns = (slug) => ({
  types: [
    GET_PRODUCT_CAMPAIGNS_REQUEST,
    GET_PRODUCT_CAMPAIGNS_SUCCESS,
    GET_PRODUCT_CAMPAIGNS_FAILURE,
  ],
  shouldCallAPI: (state) => {
    return state.staffOnly.campaigns.status !== STATUS_LOADING;
  },
  callCache: (state) => {
    if (!state.staffOnly.campaigns.data[slug]) return false;

    return {
      data: state.staffOnly.campaigns.data[slug],
    };
  },
  callAPI: () =>
    axios.get(`${API.PRODUCT_CAMPAIGNS}${slug}/`, {
      headers: {Accept: 'application/json'},
    }),
  payload: {slug},
});

export const addCampaign = (slug, campaignId, errorCallbacks) => ({
  types: [ADD_PRODUCT_CAMPAIGN_REQUEST, ADD_PRODUCT_CAMPAIGN_SUCCESS, ADD_PRODUCT_CAMPAIGN_FAILURE],
  callAPI: () =>
    axios.post(
      `${API.PRODUCT_CAMPAIGNS}${slug}/campaign-product/`,
      {campaign: campaignId},
      {headers: {Accept: 'application/json'}},
    ),
  payload: {slug, campaignId},
  onError: errorCallbacks,
});

export const deleteCampaign = (slug, campaignId, errorCallbacks) => ({
  types: [
    DELETE_PRODUCT_CAMPAIGN_REQUEST,
    DELETE_PRODUCT_CAMPAIGN_SUCCESS,
    DELETE_PRODUCT_CAMPAIGN_FAILURE,
  ],
  callAPI: () =>
    axios.delete(`${API.PRODUCT_CAMPAIGNS}${slug}/campaign-product/${campaignId}/`, {
      headers: {Accept: 'application/json'},
    }),
  payload: {slug, campaignId},
  onError: errorCallbacks,
});

export const getCollections = (slug) => ({
  types: [
    GET_PRODUCT_COLLECTIONS_REQUEST,
    GET_PRODUCT_COLLECTIONS_SUCCESS,
    GET_PRODUCT_COLLECTIONS_FAILURE,
  ],
  callCache: (state) => {
    if (!state.staffOnly.collections.data[slug]) return false;

    return {
      data: state.staffOnly.collections.data[slug],
    };
  },
  callAPI: () =>
    axios.get(`${API.COLLECTIONS_API}product-collections/${slug}/`, {
      headers: {Accept: 'application/json'},
    }),
  payload: {slug},
});

export const addToCollection = (slug, productId, collectionId, errorCallbacks) => ({
  types: [
    ADD_PRODUCT_COLLECTION_REQUEST,
    ADD_PRODUCT_COLLECTION_SUCCESS,
    ADD_PRODUCT_COLLECTION_FAILURE,
  ],
  callAPI: () =>
    axios.post(
      API.COLLECTIONS_API,
      {action: 'add', collection_id: collectionId, product_id: productId},
      {headers: {Accept: 'application/json', 'X-Requested-With': 'XMLHttpRequest'}},
    ),
  payload: {slug, collectionId},
  onError: errorCallbacks,
});

export const removeFromCollection = (slug, productId, collectionId, errorCallbacks) => ({
  types: [
    DELETE_PRODUCT_COLLECTION_REQUEST,
    DELETE_PRODUCT_COLLECTION_SUCCESS,
    DELETE_PRODUCT_COLLECTION_FAILURE,
  ],
  callAPI: () =>
    axios.post(
      API.COLLECTIONS_API,
      {action: 'remove', collection_id: collectionId, product_id: productId},
      {headers: {Accept: 'application/json', 'X-Requested-With': 'XMLHttpRequest'}},
    ),
  payload: {slug, collectionId},
  onError: errorCallbacks,
});

export const createCollection = (
  productSlug,
  name,
  description,
  successCallbacks,
  errorCallbacks,
) => ({
  types: [
    CREATE_NEW_COLLECTION_REQUEST,
    CREATE_NEW_COLLECTION_SUCCESS,
    CREATE_NEW_COLLECTION_FAILURE,
  ],
  callAPI: () =>
    axios.post(
      API.COLLECTIONS_API,
      {action: 'new', name, description},
      {headers: {Accept: 'application/json', 'X-Requested-With': 'XMLHttpRequest'}},
    ),
  payload: {slug: productSlug},
  onSuccess: successCallbacks,
  onError: errorCallbacks,
});

export const promote = (slug, days, successCallbacks, errorCallbacks) => ({
  types: [PROMOTE_PRODUCT_REQUEST, PROMOTE_PRODUCT_SUCCESS, PROMOTE_PRODUCT_FAILURE],
  callAPI: () =>
    axios.post(
      `${API.PROMOTE_PRODUCT}`,
      {product_slug: slug, days_to_promote: days},
      {headers: {Accept: 'application/json'}},
    ),
  payload: {slug},
  onSuccess: successCallbacks,
  onError: errorCallbacks,
});
