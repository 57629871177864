import styled, {css} from 'styled-components';
import {StyledProps} from './typings';
import colors from '../../../theme/constants/colors';

const textStroke = (width, color) => css`
  text-stroke: ${width}px ${color};
  -webkit-text-stroke: ${width}px ${color};
`;

const textStrokeColor = (color) => css`
  text-stroke-color: ${color};
  -webkit-text-stroke-color: ${color};
`;

const StyledFavouriteBtn = styled.button<StyledProps>`
  cursor: pointer;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;

  ${(props) => css`
    [class^='icon-'],
    [class*=' icon-'] {
      ${textStroke(1.5, props.$active ? 'transparent' : colors.darkBlue100)}
      color: ${props.$active ? colors.red100 : 'transparent'};
    }

    &:hover {
      [class^='icon-'],
      [class*=' icon-'] {
        ${textStrokeColor(props.$active ? 'transparent' : colors.red100)};
      }
    }

    &:focus {
      outline: none;
    }

    // disabled state
    ${props.$disabled &&
    css`
      pointer-events: none;

      [class^='icon-'],
      [class*=' icon-'] {
        color: ${props.$active ? colors.grey100 : 'transparent'};
        ${textStrokeColor(props.$active ? 'transparent' : colors.grey100)};
      }
    `}
  `}
`;
export default StyledFavouriteBtn;
