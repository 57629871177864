import styled, {css} from 'styled-components';
import {StyledProps} from './typings';
import pxToRem from '../../theme/helpers/pxToRem';
import colors from '../../theme/constants/colors';

const StyledPrice = styled.span<StyledProps>`
  ${(props) => css`
    .price {
      &__main {
        font-size: ${pxToRem(16)};
        font-weight: bold;
        color: ${props.$discounted ? colors.red100 : colors.darkBlue100};
        text-decoration: ${props.$sold ? 'line-through' : 'none'};
      }

      &__discount {
        font-size: ${pxToRem(12)};
        color: ${colors.darkBlue100};

        span:first-child {
          text-decoration: line-through;
        }

        span:last-child {
          margin-left: 7px;
        }
      }
    }
  `}
`;

export default StyledPrice;
