export const unitsConverter = {
  cmToInches: (cm: number, rounding: number = 0): number => {
    const multiplier = Math.pow(10, rounding);
    const inches = cm * 0.393701;
    const num = inches * multiplier * (1 + Number.EPSILON);
    return Math.round(num) / multiplier;
  },
  inchesToCm: (inches: number, rounding: number = 0): number => {
    const multiplier = Math.pow(10, rounding);
    const cm = inches * 2.54;
    const num = cm * multiplier * (1 + Number.EPSILON);
    return Math.round(num) / multiplier;
  },
  round: (num: number, rounding: number = 0): number => {
    const multiplier = Math.pow(10, rounding);
    num = num * multiplier * (1 + Number.EPSILON);
    return Math.round(num) / multiplier;
  },
};
