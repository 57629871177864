import styled, {css} from 'styled-components';
import {StyledTextfieldProps, StyledInputProps} from './typings';
import colors from '../../theme/constants/colors';
import pxToRem from '../../theme/helpers/pxToRem';
import {sizePx} from '@/theme/helpers/size';
import typography from '@/theme/typography';

export const StyledTextfield = styled.div<StyledTextfieldProps>`
  position: relative;
  width: 100%;
  ${(props) => props.$maxWidth && `max-width: ${props.$maxWidth};`}

  label {
    ${typography.Body2}
    color: ${(props) =>
      props.$disabled ? colors.grey100 : props.$error ? colors.red100 : colors.darkBlue100};
    margin-bottom: ${sizePx(2)};
  }

  > small {
    ${typography.Body3}
    color: ${(props) =>
      props.$disabled ? colors.grey100 : props.$error ? colors.red100 : colors.darkBlue100};
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateY(100%);
  }
`;

export const StyledInputContainer = styled.span`
  position: relative;

  span[class*=' icon-'],
  span[class^='icon-'] {
    position: absolute;
    right: 0;
    padding: ${sizePx(7)};
  }
`;

export const StyledInput = styled.input<StyledInputProps>`
  ${(props) => css`
    width: 100%;
    height: ${sizePx(22)};
    padding: ${sizePx(6)} ${props.$hasIcon ? sizePx(17) : sizePx(10)} ${sizePx(6)} ${sizePx(10)};
    font-size: ${pxToRem(14)};
    color: ${colors.darkBlue100};
    border: 1px solid ${props.$error ? colors.red100 : colors.grey100};
    border-radius: ${sizePx(2)};
    resize: none;
    position: relative;
    line-height: normal;
    background: #ffffff;

    &:focus {
      outline-width: 0;
      border-color: ${props.$error ? colors.red100 : colors.darkBlue100};
      outline: none;
    }

    &::placeholder {
      color: ${colors.grey100};
    }

    &[aria-disabled='true'] {
      border-color: ${colors.grey50};
      color: ${colors.grey50};
      pointer-events: none;
      background: rgba(255, 255, 255, 0.3);

      &::placeholder {
        color: ${colors.grey50};
      }
    }
  `}
`;
