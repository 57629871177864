import {useState} from 'react';
import useAuth from '../../../../hooks/use-auth/use-auth';
import constantsFactory from '../../../../utils/constants';
import Icon from '../../../atoms/Icon/Icon';
import colors from '../../../theme/constants/colors';
import {
  StyledDropdown,
  StyledListItem,
  StyledPositionContainer,
  StyledStaffToolsCog,
  StyledStaffToolsContainer,
} from './styles';
import Props from './types';
import useOutsideClick from '../../../../hooks/use-outside-click/use-outside-click';
import {useDispatch} from 'react-redux';
import {promote} from '../../../../../redux/actions/staffOnlyActions';
import Modal from '../../Modal/Modal';
import Themes from '../../Fragments/Modal/StaffTools/Themes/Themes';
import Campaigns from '../../Fragments/Modal/StaffTools/Campaigns/Campaigns';
import Collections from '../../Fragments/Modal/StaffTools/Collections/Collections';

const modalContent = {
  themes: (product) => <Themes productSlug={product.slug} productName={product.name} />,
  campaigns: (product) => <Campaigns productSlug={product.slug} productName={product.name} />,
  collections: (product) => (
    <Collections
      productSlug={product.slug}
      productId={product.id}
      imgPath={product.images?.[0].path}
    />
  ),
};

const StaffToolsCog = ({product, position = 'top-right'}: Props) => {
  // Variables
  const {ROLES} = constantsFactory();
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState<'themes' | 'campaigns' | 'collections' | null>(null);
  const [promoteForThreeDaysStatus, setPromoteForThreeDaysStatus] = useState<
    null | 'loading' | 'success' | 'failed' | 'disabled'
  >(null);
  const [promoteForOneWeekStatus, setPromoteForOneWeekStatus] = useState<
    null | 'loading' | 'success' | 'failed'
  >(null);

  const closeDropdown = () => setIsOpen(false);

  // Hooks
  const auth = useAuth();
  const dispatch = useDispatch();
  const dropdownRef = useOutsideClick(closeDropdown, isOpen);

  const toggleDropdownState = () => {
    setIsOpen(!isOpen);
  };

  const addThemes = () => {
    setShowModal('themes');
  };

  const addCampaigns = () => {
    setShowModal('campaigns');
  };

  const addToCollection = () => {
    setShowModal('collections');
  };

  const promoteForThreeDays = (e) => {
    e.stopPropagation();
    if (
      promoteForThreeDaysStatus === 'loading' ||
      promoteForThreeDaysStatus === 'success' ||
      promoteForThreeDaysStatus === 'disabled'
    )
      return;

    setPromoteForThreeDaysStatus('loading');

    const errorCallbacks = [
      () => {
        setPromoteForThreeDaysStatus('failed');
      },
    ];

    const successCallbacks = [
      () => {
        setPromoteForThreeDaysStatus('success');
      },
    ];

    dispatch(promote(product.slug, 3, successCallbacks, errorCallbacks));
  };

  const promoteForOneWeek = (e) => {
    e.stopPropagation();
    if (promoteForOneWeekStatus === 'loading' || promoteForOneWeekStatus === 'success') return;

    setPromoteForOneWeekStatus('loading');

    const errorCallbacks = [
      () => {
        setPromoteForOneWeekStatus('failed');
      },
    ];

    const successCallbacks = [
      () => {
        setPromoteForOneWeekStatus('success');
        setPromoteForThreeDaysStatus('disabled');
      },
    ];

    dispatch(promote(product.slug, 7, successCallbacks, errorCallbacks));
  };

  const tools = [
    {
      name: 'Themes',
      icon: 'image',
      function: addThemes,
    },
    {
      name: 'Campaigns',
      icon: 'emoji-happy',
      function: addCampaigns,
    },
    {
      name: 'Collections',
      icon: 'bookmark',
      function: addToCollection,
    },
    {
      name: 'Promote for 3 days',
      icon: 'star',
      function: promoteForThreeDays,
    },
    {
      name: 'Promote for 1 week',
      icon: 'star',
      function: promoteForOneWeek,
    },
  ] as const;

  const closeModal = () => {
    setShowModal(null);
  };

  return (
    <>
      {auth.isAuthorised([ROLES.STAFF]) && (
        <>
          <StyledPositionContainer $position={position} ref={dropdownRef}>
            <StyledStaffToolsContainer onClick={toggleDropdownState}>
              <StyledStaffToolsCog>
                <Icon type="settings" fontSize={15} color={colors.white} />
              </StyledStaffToolsCog>

              {isOpen && (
                <StyledDropdown>
                  {tools.map((tool, i) => (
                    <StyledListItem
                      onClick={tool.function}
                      key={i}
                      $status={
                        tool.name === 'Promote for 1 week'
                          ? promoteForOneWeekStatus
                          : tool.name === 'Promote for 3 days'
                          ? promoteForThreeDaysStatus
                          : null
                      }
                    >
                      <div>
                        <Icon type={tool.icon} />
                        {tool.name}
                      </div>
                    </StyledListItem>
                  ))}
                </StyledDropdown>
              )}
            </StyledStaffToolsContainer>
          </StyledPositionContainer>

          {showModal && <Modal onClose={closeModal}>{modalContent[showModal](product)}</Modal>}
        </>
      )}
    </>
  );
};

export default StaffToolsCog;
