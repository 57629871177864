import TextLink from '@/atoms/Links/Text/TextLink';
import React from 'react';
import {unitsConverter} from './number';

/**
 * This function makes sure that string is converted to follow key pattern we get from BE
 * Currently function replaces - with _ and converts string to lowercase
 * @param {string} input Input string to format
 * @return {string} String that meets same format as BE keys
 */
export const keyFilter = (input: string): string => {
  return input.toLowerCase().replace(/-/g, '_');
};

/**
 * This is the function to display correct artwork dimensions.
 * It will convert to different units based on user selection without any requests to BE.
 * Displayed value will be rounded to whole numbers.
 * @param {object} dimensions Dimensions object
 * @param {string} displaySizeUnits Either 'cm' or 'in'
 * @return {string} Dimensions string matching format
 */
export const getDimensions = (
  dimensions: DimensionsProps,
  displaySizeUnits: DisplayUnitsType,
): string => {
  if (!displaySizeUnits || !dimensions) return '';

  if (displaySizeUnits === 'in') {
    if (dimensions.units === 'cm') {
      return `${unitsConverter.cmToInches(dimensions.width)} x ${unitsConverter.cmToInches(
        dimensions.height,
      )}"`;
    } else {
      return `${unitsConverter.round(dimensions.width)} x ${unitsConverter.round(
        dimensions.height,
      )}"`;
    }
  } else {
    if (dimensions.units === 'in') {
      return `${unitsConverter.inchesToCm(dimensions.width)} x ${unitsConverter.inchesToCm(
        dimensions.height,
      )}cm`;
    } else {
      return `${unitsConverter.round(dimensions.width)} x ${unitsConverter.round(
        dimensions.height,
      )}cm`;
    }
  }
};

export const formatTextWithLinks = (text: string) => {
  if (!text) return '';

  const regex = /\[(.*?)\]\((.*?)\)/g;
  const parts: (string | JSX.Element)[] = [];
  let lastIndex = 0;

  text.replace(regex, (match: string, content: string, url: string, index: number) => {
    parts.push(text.slice(lastIndex, index));
    parts.push(<TextLink href={url}>{content}</TextLink>);
    lastIndex = index + match.length;

    return '';
  });

  parts.push(text.slice(lastIndex));
  return (
    <>
      {parts.map((part, i) => (
        <React.Fragment key={i}>{part}</React.Fragment>
      ))}
    </>
  );
};

export interface DimensionsProps {
  width: number; // decimal
  height: number;
  depth: number | null;
  units: DisplayUnitsType; // 'cm'
  type: string; // "unframed" - why we have it in this place?
}

export type DisplayUnitsType = 'cm' | 'in';

/**
 * Capitalizes the first letter of a given string.
 * @param {string} str Input string to format
 * @return {string} String that meets same format as BE keys
 */
export const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
