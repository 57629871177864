import React, {ReactElement, useState} from 'react';
import StyledTextarea from './styles';
import Props from './typings';

const Textarea = ({
  placeholder,
  width,
  height,
  disabled = false,
  fluidWidth,
  error = false,
  callback,
}: Props): ReactElement => {
  const [value, setValue] = useState('');

  const handleChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
    const target = event.target as HTMLTextAreaElement;
    const value = target.value.toString();
    setValue(value);
  };

  callback(value);

  return (
    <StyledTextarea
      placeholder={placeholder}
      onChange={handleChange}
      $width={width}
      $height={height}
      $fluidWidth={fluidWidth}
      $error={error}
      disabled={disabled}
      value={value}
    />
  );
};

export default Textarea;
