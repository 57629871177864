import axios from 'axios';
import constantsFactory from '../../src/utils/constants';

// Action Types
import {
  STATUS_SUCCEEDED,
  STATUS_LOADING,
  STATUS_UPDATING,
  LOAD_PRODUCT_FAVOURITES_REQUEST,
  LOAD_PRODUCT_FAVOURITES_SUCCESS,
  LOAD_PRODUCT_FAVOURITES_FAILURE,
  SAVE_PRODUCT_FAVOURITE_REQUEST,
  SAVE_PRODUCT_FAVOURITE_SUCCESS,
  SAVE_PRODUCT_FAVOURITE_FAILURE,
} from '../actionTypes';

// private actions
const {API} = constantsFactory();
const GET_FAVOURITES_URL = `${process.env.HOST}${API.FAVOURITES}`;
const SAVE_FAVOURITES_URL = `${process.env.HOST}${API.COLLECTIONS_API}`;

/**
 * Async action creator - Load Product Favourites (depends on callAPIMiddleware)
 */
export function loadProductFavourites() {
  return {
    // Types of actions to emit before and after
    types: [
      LOAD_PRODUCT_FAVOURITES_REQUEST,
      LOAD_PRODUCT_FAVOURITES_SUCCESS,
      LOAD_PRODUCT_FAVOURITES_FAILURE,
    ],
    // Check if we already have a cached result (optional):
    shouldCallAPI: (state) =>
      state.productFavourites.status !== STATUS_LOADING &&
      state.productFavourites.status !== STATUS_SUCCEEDED,
    // Perform the fetching:
    callAPI: () =>
      axios.get(GET_FAVOURITES_URL, {headers: {Accept: 'application/json'}, withCredentials: true}),
    // Arguments to inject in begin/end actions
    payload: {},
  };
}

/**
 * Async action creator - Save product Favourite  (depends on callAPIMiddleware)
 */
export function saveProductFavourite(
  productId,
  productSlug,
  artistId,
  artistSlug,
  onSuccessCallbacks = [],
) {
  return {
    // Types of actions to emit before and after
    types: [
      SAVE_PRODUCT_FAVOURITE_REQUEST,
      SAVE_PRODUCT_FAVOURITE_SUCCESS,
      SAVE_PRODUCT_FAVOURITE_FAILURE,
    ],
    // Check if we already have a cached result (optional):
    shouldCallAPI: (state) => state.productFavourites.status !== STATUS_UPDATING,
    // Perform the fetching:
    callAPI: () =>
      axios.post(
        SAVE_FAVOURITES_URL,
        {action: 'favourite', product_id: productId},
        {
          headers: {Accept: 'application/json', 'X-Requested-With': 'XMLHttpRequest'},
          withCredentials: true,
        },
      ),
    // Arguments to inject in begin/end actions
    // in this case they will be available on the reducer and accessible as action.product_id, action.product_slug...
    payload: {
      product_id: productId,
      product_slug: productSlug,
      artist_id: artistId,
      artist_slug: artistSlug,
    },
    // Functions to run on success
    onSuccess: onSuccessCallbacks,
  };
}
