import styled, {css} from 'styled-components';
import {StyledProps} from './typings';
import colors from '../../theme/constants/colors';
import pxToRem from '../../theme/helpers/pxToRem';

const StyledTextarea = styled.textarea<StyledProps>`
  ${(props) => css`
    width: ${props.$fluidWidth ? props.$width + '%' : props.$width + 'px'};
    height: ${props.$height}px;
    padding: 20px;
    font-size: ${pxToRem(14)};
    color: ${colors.darkBlue100};
    border: 1px solid ${colors.grey100};
    border-radius: 4px;
    resize: none;

    &:focus {
      outline-width: 0;
    }

    &::placeholder {
      color: ${colors.grey100};
    }

    &:disabled {
      border-color: ${colors.grey100};
      color: ${colors.grey100};
      pointer-events: none;
      background: rgba(255, 255, 255, 0.3);
    }

    //error state
    ${props.$error &&
    css`
      border-color: ${colors.red100};
    `}
  `}
`;

export default StyledTextarea;
