import React, {ReactElement} from 'react';
import StyledPrice from './styles';
import Props from './typings';

const Price = ({price, oldPrice, currency, sold}: Props): ReactElement => {
  const priceRoundUp = (price: number): number => Math.ceil(price);

  const salePercentage = (): string => {
    if (!oldPrice) return 'Cannot find old price';

    const newPercentage = (price * 100) / oldPrice;
    const percentage = Math.round(100 - newPercentage);
    return `(-${percentage}%)`;
  };

  return (
    <StyledPrice $discounted={!!oldPrice} $sold={sold}>
      <span className="price__main">
        {oldPrice ? 'Now ' : ''}
        {currency}
        {priceRoundUp(price)}
      </span>
      {!!oldPrice && (
        <>
          <br />
          <span className="price__discount">
            <span>
              {currency}
              {priceRoundUp(oldPrice)}
            </span>

            <span>{salePercentage()}</span>
          </span>
        </>
      )}
    </StyledPrice>
  );
};

export default Price;
