import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  addTheme,
  deleteTheme,
  getThemes,
} from '../../../../../../../redux/actions/staffOnlyActions';
import {STATUS_FAILED, STATUS_SUCCEEDED} from '../../../../../../../redux/actionTypes';
import {selectThemes} from '../../../../../../../redux/reducers/staffOnlyReducer';
import CtaButton from '../../../../../atoms/Buttons/CTA/CtaButton';
import Checkbox from '../../../../../atoms/Checkbox/Checkbox';
import Textfield from '../../../../Textfield/Textfield';
import {StyledChecklistArea, StyledEmbeddedMsg, StyledSlidingMsg, StyledThemes} from './styles';
import Props from './typings';

const Themes = ({productSlug, productName}: Props) => {
  // Redux selectors
  const themes = useSelector(selectThemes);

  // Hooks
  const dispatch = useDispatch();

  // Variables
  const [filterValue, setFilterValue] = useState('');
  const [showEmbeddedMsg, setShowEmbeddedMsg] = useState(false);
  const [showSlidingMsg, setShowSlidingMsg] = useState(false);
  const [loading, setLoading] = useState(false);

  const onThemeSelect = (themeId, checked) => {
    setShowSlidingMsg(false);

    const errorCallbacks = [
      () => {
        setShowSlidingMsg(true);
      },
    ];

    if (checked) {
      dispatch(deleteTheme(productSlug, themeId, errorCallbacks));
    } else {
      dispatch(addTheme(productSlug, themeId, errorCallbacks));
    }
  };

  const getProductThemes = () => {
    setLoading(true);

    dispatch(getThemes(productSlug));
  };

  useEffect(() => {
    getProductThemes();
  }, []);

  useEffect(() => {
    if (themes?.status === STATUS_FAILED) {
      setShowEmbeddedMsg(true);
      setLoading(false);
    } else if (themes?.status === STATUS_SUCCEEDED) {
      setShowEmbeddedMsg(false);
      setLoading(false);
    }
  }, [themes.status]);

  return (
    <StyledThemes>
      <h4>{productName}</h4>

      <h5>Select themes:</h5>

      <Textfield placeholder={'Filter themes'} callback={setFilterValue} icon="search" />

      <StyledSlidingMsg $show={showSlidingMsg}>
        Operation wasn't successful. Please refresh the page to get correct data!
      </StyledSlidingMsg>

      {showEmbeddedMsg ? (
        <StyledEmbeddedMsg>
          <p>Themes couldn't be retrieved</p>

          <CtaButton onClick={getProductThemes} disabled={loading}>
            Try again!
          </CtaButton>
        </StyledEmbeddedMsg>
      ) : (
        themes?.data?.[productSlug] &&
        (themes?.data?.[productSlug]?.length ? (
          <StyledChecklistArea>
            {filterValue.length > 1
              ? themes.data[productSlug]
                  .filter((option) =>
                    option.choice_label?.toLowerCase().includes(filterValue.toLowerCase()),
                  )
                  .map((option, key) => (
                    <Checkbox
                      checked={option.value}
                      label={option.choice_label}
                      value={option.id_for_label}
                      group={'themes'}
                      callback={(id) => onThemeSelect(id, option.value)}
                      style={'text'}
                      key={key}
                    />
                  ))
              : themes.data[productSlug].map((option, key) => (
                  <Checkbox
                    checked={option.value}
                    label={option.choice_label}
                    value={option.id_for_label}
                    group={'themes'}
                    callback={(id) => onThemeSelect(id, option.value)}
                    style={'text'}
                    key={key}
                  />
                ))}
          </StyledChecklistArea>
        ) : (
          <StyledEmbeddedMsg>
            <p>There are no themes matching this artwork</p>
          </StyledEmbeddedMsg>
        ))
      )}
    </StyledThemes>
  );
};

export default Themes;
