import styled, {css} from 'styled-components';
import {StyledProps} from './typings';
import colors from '../../theme/constants/colors';
import Typography from '../../theme/typography';

const textStyling = (checked, disabled) => css`
  ${Typography.Body2}
  ${checked && 'font-weight: bold;'}
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  margin: 13px 0;
  text-transform: capitalize;
  position: relative;

  svg {
    position: absolute;
    margin-left: -2px;
    display: ${checked ? 'block' : 'none'};
    font: inherit;
    color: ${colors.white};
  }

  input {
    margin: 2px 15px 0 0;
    appearance: none;
    width: 16px;
    height: 16px;
    border: 1px solid ${colors.darkBlue100};
    border-radius: 3px;
    background: #fff;
    cursor: pointer;
    position: relative;
    flex-shrink: 0;
    align-self: flex-start;

    &:checked {
      border: 1px solid ${colors.red100};
      background: ${colors.red100};
    }
  }

  ${disabled &&
  `
    color: ${colors.grey100};
    pointer-events: none;

    input,
    input:checked {
      border-color: ${colors.grey100};
      background: #fff;
    }
  `}
`;

const buttonStyling = (checked, disabled, title) => css`
  ${Typography.Body3}
  ${checked && 'font-weight: bold;'}
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  margin: 6px 5px;
  padding: 0 9px;
  text-transform: capitalize;
  border-radius: 4px;
  box-shadow: ${checked
    ? `0 0 0 1px ${colors.red100}, inset 0 0 0 1px ${colors.red100} `
    : `0 0 0 1px ${colors.darkBlue100}`};
  position: relative;
  white-space: nowrap;

  svg {
    display: none;
  }

  &::after {
    content: '${title}';
    height: 0;
    visibility: hidden;
    overflow: hidden;
    user-select: none;
    pointer-events: none;
    font-weight: bold;

    @media speech {
      display: none;
    }
  }

  input {
    margin: 0;
    appearance: none;
    font: inherit;
    border: none;
    border-radius: 0;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    flex-shrink: 0;

    &::before {
      display: none;
    }
  }

  ${disabled &&
  `
  color: ${colors.grey100};
  pointer-events: none;
  box-shadow: ${
    checked
      ? `0 0 0 1px ${colors.grey100}, inset 0 0 0 1px ${colors.grey100} `
      : `0 0 0 1px ${colors.grey100}`
  };

  input,
  input:checked {
    background: #fff;
  }
`}
`;

const StyledCheckboxLabel = styled.label<StyledProps>`
  ${(props) =>
    props.$style === 'text'
      ? textStyling(props.$checked, props.$disabled)
      : buttonStyling(props.$checked, props.$disabled, props.$title)}
`;

export default StyledCheckboxLabel;
