import {ReactElement, forwardRef} from 'react';
import Icon from '../../atoms/Icon/Icon';
import colors from '../../theme/constants/colors';
import {StyledInput, StyledInputContainer, StyledTextfield} from './styles';
import Props from './typings';

const Textfield = forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      label,
      type = 'text',
      placeholder,
      maxWidth,
      icon,
      disabled = false,
      error = false,
      helper,
      value,
      autocomplete,
      required = false,
      callback,
    },
    ref,
  ): ReactElement => {
    const iconColor = disabled
      ? colors.grey100
      : error
      ? colors.red100
      : value?.toString().trim()
      ? colors.darkBlue100
      : colors.grey100;

    return (
      <StyledTextfield $error={error} $disabled={disabled} $maxWidth={maxWidth}>
        {!!id && !!label && (
          <label htmlFor={id}>
            {label}
            {required && <span aria-label="required field">{' *'}</span>}
          </label>
        )}

        <StyledInputContainer>
          <StyledInput
            id={id}
            type={type}
            placeholder={placeholder}
            onChange={callback ? (e) => callback(e.target.value) : undefined}
            aria-label={!id && !!label ? label : undefined}
            aria-disabled={disabled}
            aria-invalid={!!error}
            aria-describedby={id ? `${id}-helper` : undefined}
            value={value}
            ref={ref}
            required={required}
            autoComplete={autocomplete}
            $error={!!error}
            $hasIcon={!!icon}
          />

          {icon && <Icon type={icon} color={iconColor} />}
        </StyledInputContainer>

        {helper && (
          <small id={id ? `${id}-helper` : undefined} aria-live="assertive" aria-atomic="true">
            {helper}
          </small>
        )}
      </StyledTextfield>
    );
  },
);

export default Textfield;
