import styled from 'styled-components';
import typography from '../../../../../theme/typography';
import colors from '../../../../../theme/constants/colors';
import {StyledCheckboxLabelProps, StyledSlidingMsgProps} from './typings';
import breakpoints from '../../../../../theme/constants/breakpoints';

export const StyledCollections = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;

  h4 {
    ${typography.Title}
    color: ${colors.darkBlue100};
    text-align: center;
    max-width: calc(100% - 15px);
    margin-top: 5px;
  }

  & > *,
  form > *:not(:first-child) {
    margin-top: 15px;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  @media only screen and ${breakpoints.device.s} {
    max-width: 400px;
  }
`;

export const StyledSlidingMsg = styled.p<StyledSlidingMsgProps>`
  background: ${colors.red25};
  border-radius: 5px;
  ${typography.Body2}
  color: ${colors.red200};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  overflow: hidden;
  height: ${(props) => (props.$show === true ? '60px' : '0px')};
  box-shadow: inset 0 0 0 1px ${colors.red50};
  transition: height 0.2s;
  padding: 0 15px;
  ${(props) => !props.$show && 'margin-top: 0 !important;'}
`;

export const StyledEmbeddedMsg = styled.div`
  padding: 25px 0 15px;
  text-align: center;

  p {
    ${typography.Art}
  }
`;

export const StyledChecklistArea = styled.div`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: calc(100% + 5px);
  margin-right: -5px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    background: ${colors.white};
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${colors.red100};
  }
  &::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: ${colors.white};
  }
`;

export const StyledCollectionImg = styled.span`
  width: 60px;
  height: 60px;
  display: block;
  text-align: center;
  grid-column: 1 / span 1;
  grid-row: 1 / span 2;
  justify-self: start;
  align-self: center;
`;

export const StyledCheckboxLabel = styled.label<StyledCheckboxLabelProps>`
  ${typography.Body2}
  height: 70px;
  width: 100%;
  cursor: pointer;
  margin: 6px 0;
  padding: 0 5px;
  text-transform: capitalize;
  border-radius: 4px;
  position: relative;
  white-space: nowrap;
  display: grid;
  grid-template-columns: 75px auto;
  grid-template-rows: 1fr 1fr;

  &:hover {
    background: rgba(124, 224, 211, 0.2);
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  ${(props) =>
    props.$checked &&
    `
  &::before {
    content: '';
    position: absolute;
    width: 17px;
    height: 10px;
    border-left: solid 4px ${colors.darkBlue75};
    border-bottom: solid 4px ${colors.darkBlue75};
    border-top: none;
    border-right: none;
    top: 0;
    bottom: 0;
    right: 15px;
    transform: rotate(-45deg);
    margin: auto 0;
  }
  `}

  input {
    margin: 0;
    appearance: none;
    font: inherit;
    border: none;
    border-radius: 0;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    flex-shrink: 0;

    &::before {
      display: none;
    }
  }

  ${StyledCollectionImg} {
    ${(props) =>
      props.$checked
        ? `background: ${props.$img ? `url('${props.$img}')` : 'none'};
        background-size: cover;`
        : `&::before{
      content: '\\e920';
      font-family: 'AF-icons';
      font-size: 45px;
      color: ${colors.grey75};
      speak: never;
      line-height: 60px;
    }`}
  }
`;

export const StyledCollectionName = styled.span`
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  justify-self: start;
  align-self: end;
`;

export const StyledCollectionInfo = styled.span`
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
  justify-self: start;
  align-self: start;
`;

export const StyledBackButton = styled.button`
  width: 25px;
  height: 25px;
  margin: 6px;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;

  &::before {
    content: '';
    width: 10px;
    height: 10px;
    display: block;
    margin: 7px 8px;
    border-bottom: 3px solid ${colors.darkBlue50};
    border-left: 3px solid ${colors.darkBlue50};
    transform: rotate(45deg);
    transition: all 0.2s;
  }

  &:hover {
    &::before {
      border-color: ${colors.red100};
    }
  }

  &:focus {
    outline: none;
  }
`;
