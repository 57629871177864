import React, {ReactElement} from 'react';
import Props from './typings';
import Icon from '../../../atoms/Icon/Icon';
import StyledFavouriteBtn from './styles';

const FavouriteButton = ({active = false, onClick, disabled}: Props): ReactElement => {
  return (
    <StyledFavouriteBtn
      $active={active}
      title={active ? 'Favourited' : 'Add to my favourites'}
      onClick={onClick}
      aria-disabled={disabled}
      $disabled={disabled}
    >
      <Icon type="heart" fontSize={17} />
    </StyledFavouriteBtn>
  );
};

export default FavouriteButton;
