import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {STATUS_FAILED, STATUS_SUCCEEDED} from '../../../../../../../redux/actionTypes';
import {
  addToCollection,
  createCollection,
  getCollections,
  removeFromCollection,
} from '../../../../../../../redux/actions/staffOnlyActions';
import {selectCollections} from '../../../../../../../redux/reducers/staffOnlyReducer';
import ImageLoader from '../../../../../../services/image-loader';
import CtaButton from '../../../../../atoms/Buttons/CTA/CtaButton';
import SubmitButton from '../../../../../atoms/Buttons/Submit/SubmitButton';
import {LoaderType} from '../../../../../atoms/Image/typings';
import Textarea from '../../../../../atoms/Textarea/Textarea';
import Textfield from '../../../../Textfield/Textfield';
import {
  StyledBackButton,
  StyledCheckboxLabel,
  StyledChecklistArea,
  StyledCollectionImg,
  StyledCollectionInfo,
  StyledCollectionName,
  StyledCollections,
  StyledEmbeddedMsg,
  StyledSlidingMsg,
} from './styles';
import Props, {CollectionListItemProps} from './typings';

const Collections = ({productSlug, productId, imgPath}: Props) => {
  // Redux selectors
  const collections = useSelector(selectCollections);

  // Hooks
  const dispatch = useDispatch();

  // Variables
  const [filterValue, setFilterValue] = useState('');
  const [page, setPage] = useState<'add' | 'create'>('add');
  const [showEmbeddedMsg, setShowEmbeddedMsg] = useState(false);
  const [showSlidingMsg, setShowSlidingMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const collectionName = useRef('');
  const collectionDescription = useRef('');
  const [collectionNameError, setCollectionNameError] = useState(false);

  const getProductCollections = () => {
    setLoading(true);
    dispatch(getCollections(productSlug));
  };

  const onCampaignSelect = (collectionId, checked) => {
    setShowSlidingMsg(false);

    const errorCallbacks = [
      () => {
        setShowSlidingMsg(true);
      },
    ];

    if (checked) {
      dispatch(removeFromCollection(productSlug, productId, collectionId, errorCallbacks));
    } else {
      dispatch(addToCollection(productSlug, productId, collectionId, errorCallbacks));
    }
  };

  const onCreateCollectionClick = (e) => {
    e.preventDefault();

    if (!collectionName.current) {
      setCollectionNameError(true);
      return;
    } else if (collectionNameError) {
      setCollectionNameError(false);
    }

    setShowSlidingMsg(false);

    const errorCallbacks = [
      () => {
        setShowSlidingMsg(true);
      },
    ];

    const successCallbacks = [
      () => {
        setPage('add');
      },
    ];

    dispatch(
      createCollection(
        productSlug,
        collectionName.current,
        collectionDescription.current,
        successCallbacks,
        errorCallbacks,
      ),
    );
  };

  const onBackButtonClick = () => {
    setPage('add');

    collectionName.current = '';
    collectionDescription.current = '';
    setCollectionNameError(false);
    setShowSlidingMsg(false);
  };

  useEffect(() => {
    if (collections?.status === STATUS_FAILED) {
      setShowEmbeddedMsg(true);
      setLoading(false);
    } else if (collections?.status === STATUS_SUCCEEDED) {
      setShowEmbeddedMsg(false);
      setLoading(false);
    }
  }, [collections.status]);

  useEffect(() => {
    if (!collections.data[productSlug]) {
      getProductCollections();
    }
  }, [collections.data]);

  const CollectionListItem = ({
    label,
    value,
    disabled = false,
    checked,
    group,
    totalItems,
    callback,
  }: CollectionListItemProps) => {
    const isLoader = (loader: string): loader is LoaderType => {
      switch (loader) {
        case 'thumbor':
        case 'default':
          return true;
        default:
          console.error('Wrong loader is passed to image!');
          return false;
      }
    };

    const getLoader = (path, width, quality): string => {
      let loader;
      if (process.env.NEXT_IMAGE_LOADER && isLoader(process.env.NEXT_IMAGE_LOADER)) {
        loader = process.env.NEXT_IMAGE_LOADER;
      } else {
        loader = 'default';
      }
      // eslint-disable-next-line new-cap
      return ImageLoader(path, width, quality).get(loader);
    };

    return (
      <StyledCheckboxLabel
        aria-disabled={disabled}
        $checked={checked}
        $img={getLoader(imgPath, 60 * 2, 100)}
      >
        <input type="checkbox" name={group} checked={checked} onChange={() => callback(value)} />

        <StyledCollectionImg />

        <StyledCollectionName>{label}</StyledCollectionName>

        <StyledCollectionInfo>{`${totalItems} ${
          totalItems === 1 ? 'artwork' : 'artworks'
        }`}</StyledCollectionInfo>
      </StyledCheckboxLabel>
    );
  };

  const addPage = () => {
    return (
      <>
        <h4>Add to collection</h4>

        <Textfield
          value={filterValue}
          placeholder={'Filter collections'}
          callback={setFilterValue}
          icon="search"
        />

        <StyledSlidingMsg $show={showSlidingMsg}>
          Operation wasn't successful. Please refresh the page to get correct data!
        </StyledSlidingMsg>

        {showEmbeddedMsg ? (
          <StyledEmbeddedMsg>
            <p>Campaigns couldn't be retrieved</p>

            <CtaButton onClick={getProductCollections} disabled={loading}>
              Try again!
            </CtaButton>
          </StyledEmbeddedMsg>
        ) : (
          collections?.data?.[productSlug] &&
          (collections?.data?.[productSlug]?.length ? (
            <StyledChecklistArea>
              {filterValue.length > 1
                ? collections.data[productSlug]
                    .filter((option) =>
                      option.name?.toLowerCase().includes(filterValue.toLowerCase()),
                    )
                    .map((option, key) => (
                      <CollectionListItem
                        label={option.name}
                        value={option.id}
                        checked={option.is_product_in_collection}
                        group="collections"
                        callback={(id) => onCampaignSelect(id, option.is_product_in_collection)}
                        totalItems={option.total_items}
                        key={key}
                      />
                    ))
                : collections.data[productSlug].map((option, key) => (
                    <CollectionListItem
                      label={option.name}
                      value={option.id}
                      checked={option.is_product_in_collection}
                      group="collections"
                      callback={(id) => onCampaignSelect(id, option.is_product_in_collection)}
                      totalItems={option.total_items}
                      key={key}
                    />
                  ))}
            </StyledChecklistArea>
          ) : (
            <StyledEmbeddedMsg>
              <p>There are no campaigns matching this artwork</p>
            </StyledEmbeddedMsg>
          ))
        )}

        <CtaButton
          maxWidth="100%"
          onClick={() => {
            setPage('create');
          }}
        >
          Create a new collection
        </CtaButton>
      </>
    );
  };

  const createPage = () => {
    return (
      <>
        <StyledBackButton onClick={onBackButtonClick} />

        <h4>Create new collection</h4>

        <form onSubmit={onCreateCollectionClick}>
          <Textfield
            placeholder="Name"
            callback={(value) => {
              collectionName.current = value;
            }}
            error={collectionNameError}
          />

          <Textarea
            width={100}
            height={125}
            fluidWidth={true}
            placeholder="Description"
            callback={(value) => {
              collectionDescription.current = value;
            }}
          />

          <StyledSlidingMsg $show={showSlidingMsg}>Couldn't create collection.</StyledSlidingMsg>

          <SubmitButton maxWidth="100%">Create</SubmitButton>
        </form>
      </>
    );
  };

  const collectionPages = {
    add: addPage,
    create: createPage,
  };

  return <StyledCollections>{collectionPages[page]()}</StyledCollections>;
};

export default Collections;
